/* eslint-disable max-len */
import { initializeGlobalProps } from '@hmn/coolinarika-web-core/helpers'
import { storeWrapper } from '@hmn/coolinarika-web-core/store'

import { ErrorPage } from '@hmn/coolinarika-web-ui/components/ErrorPage'

import { Motion } from '@web/components'
import { Column, Row } from '@web/layouts'

const FourOhFour = ({ ...rest }) => (
    <Motion.Route {...rest}>
        <Row variant="error.top">
            <Column variant="grid.12">
                <Column variant="grid.12.col.12x1">
                    <ErrorPage
                        title="Ups, nešto je pošlo po zlu!"
                        lead="Ove stranice nema. Nestala je. Sakrila se. Pokušaj pronaći nešto drugo što ti se sviđa (tražilica) ili pogledaj preporuke koje ti nudimo na naslovnici."
                    />
                </Column>
            </Column>
        </Row>
    </Motion.Route>
)

export const getStaticProps = storeWrapper.getStaticProps(async ctx => {
    const initialProps = await initializeGlobalProps({ ...ctx, dehydratedState: {}, ignoreSession: true })

    return {
        props: {
            ...initialProps
        },
        revalidate: false
    }
})

export default FourOhFour
